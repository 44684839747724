import React, { useState, useEffect } from "react";
import axios from "axios";
import List from '@mui/material/List';
import PerFolder from "./PerFolder.jsx";
import PerFile from "./PerFile.jsx";
import PerUnkownFile from "./PerUnknownFile.jsx";
import Uploader from "./Uploader.jsx";
import UndoIcon from '@mui/icons-material/Undo';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import LogoutIcon from '@mui/icons-material/Logout';
import ButtonGroup from "@mui/material/ButtonGroup"


function FolderContent() {

const [message,setMessage] = useState("");


    function QuitFromApp() {
        localStorage.clear();
        setMessage("Lütfen Sayfayı yenileyin");
    
    }
    

    // Başlangıçta root klasörü olarak "./" belirlendi
    const [folder, setFolder] = useState("./");
    const [files, setFiles] = useState({ files: [], folders: [], unknownFiles: [] });
    console.log(folder);
    async function fetchData() {
        try {
            const response = await axios.post("https://avomodoro.space/files_get", { url: folder });
            setFiles(response.data);
        } catch (error) {
            console.error("Hata", error);
        }
    }
    // Önceki klasöre geri dönmek için kullanılan fonksiyon
    function previousFolder() {
        setFolder((prevFolder) => {
            console.log(prevFolder);
            // Önceki klasör yolunu al
            const parts = prevFolder.split("/");
            // Son dizin adını silerek bir önceki klasör yolunu oluştur
            parts.pop();
            const newFolder = parts.join("/");
            if(prevFolder == "./"){
           return prevFolder;
            } else{
                return newFolder;
            }
 
        });
        fetchData();
    }
    
    // Herhangi bir klasör değişikliğinde dosyaları yeniden almak için useEffect kullanılır
    useEffect(() => {
    
        fetchData();
    }, [folder]); // folder değiştiğinde useEffect yeniden çağrılır

    // Dosya ve klasör listeleri oluşturulur
    const { files: all_files = [], folders: all_folders = [], unknownFiles: unknowns_files = [] } = files;
    const allFileForScreen = all_files.map((file) => <PerFile url={"/"} key={file} setFolder={setFolder} name={file} />);
    const allFolderForScreen = all_folders.map((folder) => <PerFolder url={"/"} key={folder} setFolder={setFolder} name={folder} />);
    const allUnkownFileForScreen = unknowns_files.map((file) => <PerUnkownFile url={"/"} setFolder={setFolder} key={file} name={file} />);

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>
                {/* Geri dönüş butonu */}
                <ListItem style={{ backgroundColor: "yellowgreen" }} onClick={()=>previousFolder()}>
    <ListItemAvatar>
        <IconButton color="default">
            <UndoIcon />
        </IconButton>
    </ListItemAvatar>
</ListItem>

                {/* Tüm dosya ve klasörlerin listesi */}
                {allFolderForScreen}
                {allFileForScreen}
                {allUnkownFileForScreen}
            </List>
            {/* Dosya yükleme bileşeni */}
            <div style={{ position: "fixed", bottom: 0, right: 0 }}>
               
         <ButtonGroup>
                <Uploader folder={folder} />
                <IconButton onClick={QuitFromApp} component={"label"} variant='contained' color='info'>
{
message
}
                <LogoutIcon />
                </IconButton>
                </ButtonGroup>
            </div>
        </>
    );
}

export default FolderContent;
