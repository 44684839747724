import React, { useState } from "react";


import FolderContent from "./FolderContent.jsx";







function App2() {



  return (

<>

<FolderContent />
</>
  );
}





export default App2;
