import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';




const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



function postFile() {
    document.getElementById("uploadViaForm").submit();  
}


function Uploader({folder}) {

    return (
        <>
<form action='https://avomodoro.space/upload' encType='multipart/form-data' id='uploadViaForm' method='post'>
                      <input type="hidden" name="uploadFolder" value={folder} />


        <Button component={"label"} variant='contained' color='info'>

        <VisuallyHiddenInput onInput={postFile} id='fileUpload' name='fileUpload' type='file'/>   


            
            <DriveFolderUploadIcon /><Typography padding={1}> Dosya</Typography>
        
        </Button>

        </form>
        </>
    );    
}

export default Uploader;