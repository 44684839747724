
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import Popper from '@mui/material/Popper';

function PerFile({name,url,createdOn,setFolder}) {
const warningOpen = true;

    return (
<>
<ListItem  style={{backgroundColor: "ButtonHighlight"}}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText primary={name} secondary={createdOn} />

        <Popper open={warningOpen}>
        <Typography></Typography>
        </Popper>

                    <IconButton color='info' edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
        
      </ListItem>









</>
    );
}

export default PerFile;