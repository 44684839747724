
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from "@mui/material/Typography";
import Popper from '@mui/material/Popper';
import Avatar from '@mui/material/Avatar';

function PerFolder({name,url,type,createdOn,setFolder}) {
    const warningOpen = true;

    return (
<>
      <ListItem onClick={()=>setFolder((prev)=>prev + "/" + name)} style={{backgroundColor: "ButtonFace", color:'ActiveCaption'}}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon color='action' />
          </Avatar>
        </ListItemAvatar>

        <ListItemText style={{color:'ActiveBorder'}} primary={name} secondary={createdOn} />



        <Popper open={warningOpen}  edge="end">
        <Typography></Typography>
        </Popper>

    <IconButton color='default' aria-label="delete">
        
      <DeleteIcon />
    </IconButton>

                 
      </ListItem>

</>
    );
}

export default PerFolder;