import * as React from 'react';

import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import "./App.css"
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import { useState } from 'react';
import App2 from "./App.jsx";
import axios from 'axios';


<Sheet
  sx={{
    width: 300,
    mx: 'auto', // margin left & right
    my: 4, // margin top & bottom
    py: 3, // padding top & bottom
    px: 2, // padding left & right
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: 'sm',
    boxShadow: 'md',
  }}
>
  Welcome!
</Sheet>



function ModeToggle() {
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
  
    // necessary for server-side rendering
    // because mode is undefined on the server
    React.useEffect(() => {
      setMounted(true);
    }, []);
    if (!mounted) {
      return null;
    }
  
    return (
      <Button
        variant="outlined"
        onClick={() => {
          setMode(mode === 'light' ? 'dark' : 'light');
        }}
      >
        {mode === 'light' ? 'Karanlık Mod' : 'Aydınlık Mod'}
      </Button>
    );
  }
  




export default function App() {

    const [gottaWarn,setWarn] = useState("şifre girin");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    const [isPasswordCorrect, setIsPasswordCorrect] = useState(Boolean(localStorage.getItem("isTruePW"))??false);


    async function tryYourPassword() {



       const result = await axios.post("https://avomodoro.space/controlMe",{password:password,name:name});


   if(result.data === "şifre doğru"){
    setIsPasswordCorrect(true);
    localStorage.setItem("isTruePW",isPasswordCorrect);
   } else {
    setIsPasswordCorrect(false);
    setWarn("Şifre yanlış");

    setTimeout(() => {
        setWarn("Lütfen Tekrar Deneyin");
    }, 1000);



   }
   }


if(isPasswordCorrect){
   





    return <App2 />;













} else {
  return (

<div className='bg2' style={{ borderRadius: 10, width: "50vw", padding: 15, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>

    <CssVarsProvider>

         <ModeToggle />
<div>
  <Typography level="h4" color='neutral' component="h1">
    Hoşgeldin!
  </Typography>
  <Typography color='warning' variant='soft'>
    {
        gottaWarn
}
</Typography>



  <FormControl>
  <FormLabel>Kullanıcı Adı:</FormLabel>
  <Input
        type="text"
        placeholder="Adınız"
        value={name}
        onChange={(e) => setName(e.target.value)}
    />
</FormControl>
<FormControl>
  <FormLabel>Parolanız</FormLabel>
  <Input
        type="password"
        placeholder="Parolanız"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
    />
</FormControl>
<Button onClick={tryYourPassword} sx={{ mt: 1 /* margin top */ }}>
  Log in
</Button>



{
/*


<Typography
  endDecorator={<Link href="/sign-up">Sign up</Link>}
  fontSize="sm"
  sx={{ alignSelf: 'center' }}
>
  Don't have an account?
</Typography>

 */

}

</div>

    </CssVarsProvider>
    </div>
  );
}
}
