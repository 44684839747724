
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import Popper from '@mui/material/Popper';
import HelpIcon from '@mui/icons-material/Help';

function PerUnkownFile({name,url,createdOn,setFolder}) {
const warningOpen = true;

    return (
<>
      <ListItem  onClick={()=>setFolder(name)}>
        <ListItemAvatar>
          <Avatar>
            <HelpIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText primary={name} secondary={createdOn} />

        <Popper open={warningOpen}>
        <Typography></Typography>
        </Popper>

                    <IconButton color='warning' edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
      
      </ListItem>











</>
    );
}

export default PerUnkownFile;